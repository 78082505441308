import ActionTypes from '@constants/actionTypes';
import Dispatcher from '@services/AppDispatcher';
import WebApi from '@services/webApi';
var AgencyActionCreators = {
    getAccountList: function () {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_ACCOUNTS_REQUEST,
        });
        WebApi.getAccountList();
    },
    agencyLogin: function (model, callback) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.GET_AGENCY_LOGIN_REQUEST,
            data: model,
        });
        WebApi.agencyLogin(model, function () {
            WebApi.getPermissions(callback);
        });
    },
    UpdateFavorite: function (model) {
        Dispatcher.handleViewAction({
            actionType: ActionTypes.UPDATE_ACCOUNT_FAVORITE_REQUEST,
            data: model,
        });
        WebApi.UpdateFavorite(model);
    },
};
export default AgencyActionCreators;
