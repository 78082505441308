var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
var UPDATE_AGENCY_FAVORITE_EVENT = 'updateFavoriteAccount';
var AGENCY_LOGIN_EVENT = 'agencyLoginEvent';
var AgencyStore = /** @class */ (function (_super) {
    __extends(AgencyStore, _super);
    function AgencyStore() {
        var _this = _super.call(this) || this;
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    AgencyStore.prototype.initializeState = function () {
        this.agencies = [];
        this.favouriteAgencies = [];
    };
    AgencyStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    AgencyStore.prototype.toggleFavorite = function (accountId) {
        var agencyIndex = this.agencies.findIndex(function (ag) { return ag.id === accountId; });
        if (agencyIndex > -1) {
            this.agencies[agencyIndex].isFavorite =
                !this.agencies[agencyIndex].isFavorite;
        }
    };
    AgencyStore.prototype.getAgencies = function () {
        return __spreadArray([], this.agencies, true);
    };
    AgencyStore.prototype.setAgencies = function (agencies) {
        this.agencies = agencies;
    };
    AgencyStore.prototype.setInitialized = function (val) {
        this.initialized = val;
    };
    AgencyStore.prototype.emitUpdateAccount = function () {
        this.emit(UPDATE_AGENCY_FAVORITE_EVENT);
    };
    AgencyStore.prototype.updateAccountListener = function (callback) {
        this.on(UPDATE_AGENCY_FAVORITE_EVENT, callback);
    };
    AgencyStore.prototype.removeUpdateAccountListener = function (callback) {
        this.removeListener(UPDATE_AGENCY_FAVORITE_EVENT, callback);
    };
    AgencyStore.prototype.emitOnAgencyLogin = function () {
        this.emit(AGENCY_LOGIN_EVENT);
    };
    AgencyStore.prototype.addOnAgencyLoginListener = function (callback) {
        this.on(AGENCY_LOGIN_EVENT, callback);
    };
    AgencyStore.prototype.removeOnAgencyLoginListener = function (callback) {
        this.removeListener(AGENCY_LOGIN_EVENT, callback);
    };
    AgencyStore.prototype.registerToActions = function (payload) {
        var _a, _b;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_LOGIN_RESPONSE:
                if (action.json && action.json.resulType === 'SUCCESS') {
                    if (((_b = (_a = action.json) === null || _a === void 0 ? void 0 : _a.agencies) === null || _b === void 0 ? void 0 : _b.length) > 0) {
                        this.setInitialized(true);
                        this.setAgencies(action.json.agencies);
                    }
                }
                else {
                    this.emitError('GET_LOGIN');
                }
                break;
            case ActionTypes.GET_ACCOUNTS_RESPONSE:
                if (action.json) {
                    var data = action.json.data;
                    this.agencies = data;
                    this.initialized = true;
                    this.emitChange();
                }
                else {
                    this.emitError('GET_ACCOUNTLIST');
                }
                break;
            case ActionTypes.GET_AGENCY_LOGIN_RESPONSE:
                if (action.json) {
                    this.emitOnAgencyLogin();
                }
                else {
                    this.emitError('GET_AGENCY_LOGIN_RESPONSE');
                }
                break;
            /* Update Favorite Agency Account */
            case ActionTypes.UPDATE_ACCOUNT_FAVORITE_RESPONSE:
                if (action.json) {
                    var accountId = action.json.model.accountId;
                    this.toggleFavorite(accountId);
                    this.emitUpdateAccount();
                }
                else {
                    this.emitError('UPDATE_FAVORITE_ACCOUNT');
                }
                break;
            default:
                break;
        }
    };
    return AgencyStore;
}(BaseStore));
export default new AgencyStore();
