var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import ActionTypes from '@constants/actionTypes';
import BaseStore from '@stores/BaseStore';
import { binarySearchObj } from '@utils/helpers';
var GET_CATEGORIES_EVENT = 'getCategoriesEvent';
var GET_CATEGORIES_GROUPS_EVENT = 'getCategoriesGroupsEvent';
var GET_CATEGORIES_GROUPS_MEMBERS_EVENT = 'getCategoriesGroupsMembers';
var UPDATE_CATEGORY_EVENT = 'updateCategoryEvent';
var DELETE_CATEGORY_EVENT = 'deleteCategoryEvent';
var CategoriesStore = /** @class */ (function (_super) {
    __extends(CategoriesStore, _super);
    function CategoriesStore() {
        var _this = _super.call(this) || this;
        _this.getIsAdmin = function () {
            return _this.isAdmin;
        };
        _this.setIsAdmin = function (value) {
            _this.isAdmin = value;
        };
        _this.initializeState();
        _this.subscribe(function () { return _this.registerToActions.bind(_this); });
        return _this;
    }
    CategoriesStore.prototype.initializeState = function () {
        this._categories = [];
        this._categoriesGroups = [];
        this._categoriesGroupsMembers = [];
        this.filtredUsers = [];
        this.categoryId = 0;
        this._usersGroups = [];
        this.isAdmin = false;
    };
    CategoriesStore.prototype.reset = function () {
        _super.prototype.reset.call(this);
        this.initializeState();
    };
    Object.defineProperty(CategoriesStore.prototype, "categories", {
        get: function () {
            return this._categories;
        },
        set: function (categories) {
            this._categories = categories;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CategoriesStore.prototype, "categoriesGroups", {
        get: function () {
            return this._categoriesGroups;
        },
        set: function (categoriesGroups) {
            this._categoriesGroups = categoriesGroups;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CategoriesStore.prototype, "categoriesGroupsMembers", {
        get: function () {
            return this._categoriesGroupsMembers;
        },
        set: function (categoriesGroupsMembers) {
            this._categoriesGroupsMembers = categoriesGroupsMembers;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CategoriesStore.prototype, "usersGroups", {
        get: function () {
            return this._usersGroups;
        },
        set: function (usersGroups) {
            this._usersGroups = usersGroups;
        },
        enumerable: false,
        configurable: true
    });
    CategoriesStore.prototype.getCategoryById = function (categoryId) {
        if (!categoryId)
            return;
        return this.categories.find(function (category) { return category.id === categoryId; });
    };
    CategoriesStore.prototype.delete = function (cateId) {
        var res = this.categories.filter(function (itemX) { return itemX.id !== cateId; });
        this.categories = res;
    };
    CategoriesStore.prototype.updateCategory = function (data) {
        var res = this.categories.map(function (category) {
            return category.id === data.id
                ? __assign(__assign({}, category), { name: data.name, css: data.css, privacy: data.privacy }) : category;
        });
        this.categories = res;
    };
    CategoriesStore.prototype.getCategoryId = function () {
        return this.categoryId;
    };
    CategoriesStore.prototype.setCategoryId = function (categoryId) {
        this.categoryId = categoryId;
    };
    CategoriesStore.prototype.emitGetCategories = function (type) {
        this.emit(GET_CATEGORIES_EVENT, type);
    };
    CategoriesStore.prototype.addGetCategoriesListener = function (callback) {
        this.on(GET_CATEGORIES_EVENT, callback);
    };
    CategoriesStore.prototype.removeGetCategoriesListener = function (callback) {
        this.removeListener(GET_CATEGORIES_EVENT, callback);
    };
    CategoriesStore.prototype.emitGetCategoriesGroups = function () {
        this.emit(GET_CATEGORIES_GROUPS_EVENT);
    };
    CategoriesStore.prototype.addGetCategoriesGroupsListener = function (callback) {
        this.on(GET_CATEGORIES_GROUPS_EVENT, callback);
    };
    CategoriesStore.prototype.removeGetCategoriesGroupsListener = function (callback) {
        this.removeListener(GET_CATEGORIES_GROUPS_EVENT, callback);
    };
    CategoriesStore.prototype.emitGetCategoriesGroupsMembers = function () {
        this.emit(GET_CATEGORIES_GROUPS_MEMBERS_EVENT);
    };
    CategoriesStore.prototype.addGetCategoriesGroupsMembersListener = function (callback) {
        this.on(GET_CATEGORIES_GROUPS_MEMBERS_EVENT, callback);
    };
    CategoriesStore.prototype.removeGetCategoriesGroupsMembersListener = function (callback) {
        this.removeListener(GET_CATEGORIES_GROUPS_MEMBERS_EVENT, callback);
    };
    CategoriesStore.prototype.emitUpdateCategory = function () {
        this.emit(UPDATE_CATEGORY_EVENT);
    };
    CategoriesStore.prototype.addUpdateCategoryListener = function (callback) {
        this.on(UPDATE_CATEGORY_EVENT, callback);
    };
    CategoriesStore.prototype.removeUpdateCategoryListener = function (callback) {
        this.removeListener(UPDATE_CATEGORY_EVENT, callback);
    };
    CategoriesStore.prototype.emitdeleteCategory = function () {
        this.emit(DELETE_CATEGORY_EVENT);
    };
    CategoriesStore.prototype.addDeleteCategoryListener = function (callback) {
        this.on(DELETE_CATEGORY_EVENT, callback);
    };
    CategoriesStore.prototype.removeDeleteCategoryListener = function (callback) {
        this.removeListener(DELETE_CATEGORY_EVENT, callback);
    };
    CategoriesStore.prototype.registerToActions = function (payload) {
        var _a, _b;
        var action = payload.action;
        switch (action.actionType) {
            case ActionTypes.GET_CATEGORIES_RESPONSE:
                if ((_a = action.json) === null || _a === void 0 ? void 0 : _a.data) {
                    var _c = action.json.data, clipSelections = _c.clipSelections, groups = _c.groups, users = _c.users, isAdmin = _c.isAdmin;
                    this.categories = clipSelections === null || clipSelections === void 0 ? void 0 : clipSelections.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    this.categoriesGroups = groups;
                    this.categoriesGroupsMembers = users;
                    this.setIsAdmin(Boolean(isAdmin));
                    this.initialized = true;
                    this.emitGetCategories('GET_CATEGORIES_LIST');
                    this.emitGetCategoriesGroups();
                    this.emitGetCategoriesGroupsMembers();
                }
                else {
                    this.emitError('GET_CATEGORIES');
                }
                break;
            case ActionTypes.GET_CATEGORIES_GROUPS_RESPONSE:
                if ((_b = action.json) === null || _b === void 0 ? void 0 : _b.data) {
                    this.usersGroups = action.json.data;
                    this.initialized = true;
                    this.emitGetCategoriesGroups();
                }
                else {
                    this.emitError('GET_CATEGORIES_GROUPS');
                }
                break;
            case ActionTypes.ADD_CLIP_SELECTION_RESPONSE:
                if (action.json) {
                    var _d = action.json, data = _d.data, message = _d.message;
                    if (data) {
                        var newCategories = __spreadArray([], this.categories, true);
                        var index = binarySearchObj(newCategories, data, 'name');
                        newCategories.splice(index, 0, data);
                        this.categories = newCategories;
                        this.setCategoryId(data.id);
                        this.initialized = true;
                        this.emitGetCategories();
                    }
                    else if (!data && message) {
                        this.emitError(message);
                    }
                }
                else {
                    this.emitError('ADD_CLIP_SELECTION');
                }
                break;
            case ActionTypes.UPDATE_CLIP_SELECTION_RESPONSE:
                if (action.json) {
                    var _e = action.json, data = _e.data, message = _e.message;
                    if (data) {
                        this.updateCategory(data);
                        this.emitGetCategories();
                    }
                    else if (!data && message) {
                        this.emitError(message);
                    }
                }
                else {
                    this.emitError('UPDATE_CLIP_SELECTION');
                }
                break;
            case ActionTypes.DELETE_CLIP_SELECTION_RESPONSE:
                if (action.json) {
                    var data = action.json.data.data;
                    this.delete(data);
                    this.setCategoryId(0);
                    this.emitdeleteCategory();
                }
                else {
                    this.emitError('DELETE_CLIP_SELECTION');
                }
                break;
            case ActionTypes.SET_CLIP_SELECTION_AUTHORIZATION_RESPONSE:
                if (action.errors) {
                    this.emitError('SELECTED_CLIP_SELECTION_AUTHORIZATION');
                }
                break;
            default:
                break;
        }
    };
    return CategoriesStore;
}(BaseStore));
export default new CategoriesStore();
